import { Box, Center, Text } from '@chakra-ui/react';
import Image from 'next/image';

import Page from '@inspire/ui/chakra/layout/Page';
import PageHeading from '@inspire/ui/chakra/layout/PageHeading';

import Layout from 'components/layout/Layout';
import TravelImage from '@inspire/ui/assets/undraw/travel.svg';
import LinkButton from 'components/general/LinkButton';
import { CONNEXION_PATH } from 'lib/paths';
import {
  GTM_ACTION_CONNEXION,
  GTM_CATEGORY_CONNEXION,
  pushEvent,
} from 'lib/gtm';

const PedagogiePage = () => (
  <Page title="INSPIRE Équipes Pédagogiques">
    <Box textAlign="center">
      <PageHeading>Du nouveau pour les équipes pédagogiques</PageHeading>

      <Image
        src={TravelImage}
        width={250}
        height={250}
        alt="INSPIRE Équipes Pédagogique bouge"
      />

      <Text>
        Votre espace pédagogique est désormais intégré au nouveau site d'
        <strong>INSPIRE</strong> (et vous êtes déjà dessus !). Retrouvez vos
        élèves et leurs aspirations ainsi que nos supports d'activités en vous
        connectant avec le bouton ci-dessous.
      </Text>

      <Text textAlign="center">
        Veuillez noter qu'il est désormais nécessaire de{' '}
        <strong>créer une classe</strong> sur le site, et d'y{' '}
        <strong>inviter vos élèves</strong> afin d'accéder à leurs informations
        et pistes recommandées.
      </Text>
      <Center>
        <LinkButton
          href={CONNEXION_PATH}
          onClick={() =>
            pushEvent(
              GTM_CATEGORY_CONNEXION,
              GTM_ACTION_CONNEXION,
              'Je me connecte (pedagogie)'
            )
          }
        >
          Je me connecte
        </LinkButton>
      </Center>
    </Box>
  </Page>
);

PedagogiePage.getWrappers = (page) => (
  <Layout rowVariant="gray" containerWidth="sm" centerVertically>
    {page}
  </Layout>
);

export default PedagogiePage;
